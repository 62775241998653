import React from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "../../../global/components/BaseDialog";

function PreselectionDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isPromoOpen,
    state.setIsPromoOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle=""
        modalBody={<ModalBody closeModal={closeModal} />}
        size="lg"
        showClose={false}
      />
    </>
  );
}

function ModalBody({ closeModal }) {
  return (
    <>
      <div className="text-md font-normal text-black sm:text-base">
        <div className="-mx-4 -mt-6 mb-4 flex items-center justify-center">
          <img
            className="mx-auto w-full"
            src={`/build/images/finals/brand/finals-promo.png`}
            alt={`Play Finals Fantasy`}
          />
        </div>
        <h3 className="mb-4 text-center text-xl font-semibold dark:text-gray-400 sm:text-2xl">
          Finals Fantasy is here!
        </h3>
        {/*<div className="mb-4 px-2 text-black dark:text-gray-400">*/}
        {/*  Can you out-captain Calvin? Out-project Popowski?*/}
        {/*</div>*/}
        <div className="mb-6 text-center text-black dark:text-gray-400">
          It's finals time and the fun doesn't stop – take on the experts in the
          all-new <span className="font-semibold">Finals Fantasy</span>!
        </div>
        <div className="text-center">
          <a
            href="https://finalsfantasy.com.au"
            className="mx-auto inline-block mb-2 rounded-lg bg-[#aa8c58] px-5 py-2 text-center font-medium text-white hover:bg-[#c2a065] disabled:opacity-50 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Play Now →
          </a>
        </div>
      </div>
    </>
  );
}

export default PreselectionDialog;
